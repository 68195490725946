import type {
  FinalizeRafflePathParams,
  GetRandomProductImageResponse,
  PublishRafflePathParams,
  RaffleFilters,
  RafflePrice,
  RaffleSort,
  ScheduleRafflePathParams,
  ScheduleRaffleRequestBody,
  TransformRaffleToTransactionalPathParams,
  Raffle as TypedRaffle,
  UpdateRaffleCustomFieldPathParams,
  UpdateRaffleCustomFieldRequestBody,
} from '@sneakersnstuff/sns-api-client/admin'
import type { BCInstance } from '@sneakersnstuff/sns-api-client/app/types'
import { ApiError } from '@sneakersnstuff/sns-api-client/error/ApiError'

import type { product_Full } from 'bigcommerce-api-client/lib/model/generated/catalog.v3'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useAdminApi } from '~/services/adminApi'
import { raffleOverviewDefaultSettings, useRaffleOverviewSettingsQuery } from './rafflesOverviewHooks'

// BigCommerce typings are off, lets make a fixed typing
export interface Raffle extends Omit<TypedRaffle, 'product'> {
  signups: number
  instance: BCInstance
  product: product_Full
}

export interface GetPaginatedRaffles {
  raffles: Raffle[]
  totalPages: number
  total: number
}

export const useRaffleSignupsQuery = (
  sku: string,
  instance: BCInstance | null,
  page: number,
  limit: number,
  sort: string,
  includeBC = false,
) => {
  const { signups } = useAdminApi()
  return useQuery({
    enabled: !!instance && !!sku,
    queryKey: ['raffle', 'signups', instance, sku, page, limit, sort, includeBC],
    queryFn: async () => {
      if (!instance) {
        return
      }
      const queryParams = {
        page: page.toString(),
        limit: limit.toString(),
        includeBC: includeBC.toString(),
        sort: sort.toString(),
      }
      const response = await signups.getSignupsBySku({ sku }, queryParams)
      return response
    },
  })
}

export const useRaffleQuery = (sku: string, instance: BCInstance | null, useRefetchInterval = false) => {
  const { raffles } = useAdminApi()
  return useQuery({
    ...(useRefetchInterval && { refetchInterval: 30 * 1000 }),
    enabled: !!instance && !!sku,
    queryKey: ['raffle', instance, sku],
    queryFn: async (): Promise<Raffle | null> => {
      if (!instance) {
        return null
      }
      try {
        const response = await raffles.getRaffle({ sku, instance })
        if (response) {
          const product: product_Full = response.product as unknown as product_Full
          return {
            product,
            signups: response.signups,
            instance: response.instance,
          }
        }
      } catch (error) {
        if (error instanceof ApiError && error.statusCode !== 404) {
          throw error
        }
      }

      return null
    },
  })
}

export const useRafflePricingQuery = (sku: string, instance: BCInstance | null) => {
  const { raffles } = useAdminApi()
  return useQuery({
    enabled: !!instance && !!sku,
    queryKey: ['raffle', instance, sku, 'pricing'],
    queryFn: async (): Promise<RafflePrice[]> => {
      if (!instance) {
        return []
      }
      try {
        const response = await raffles.getRafflePricing({ sku, instance })
        if (response) {
          return response
        }
      } catch (error) {
        if (error instanceof ApiError && error.statusCode !== 404) {
          throw error
        }
        console.log('error', error)
      }

      return []
    },
  })
}

export const useRaffleDashboardQuery = (sku: string, instance: BCInstance | null) => {
  const { raffles } = useAdminApi()
  return useQuery({
    enabled: !!instance && !!sku,
    queryKey: ['raffle', 'dashboard', instance, sku],
    queryFn: async () => {
      if (!instance || !sku) {
        return null
      }
      const response = await raffles.getRaffleDashboard({ sku, instance })
      return response
    },
  })
}

const getRaffleQueryParams = (page: number, limit: number, filters: RaffleFilters, sort: RaffleSort) => {
  return {
    page: page.toString(),
    limit: limit.toString(),
    delayed: filters.delayed,
    instances: filters.instances,
    keyword: filters.keyword,
    sortKey: sort.sortKey,
    sortOrder: sort.sortOrder,
    dateRangeKey: filters.dateRangeKey,
    dateRangeStartDate: filters.dateRangeStartDate,
    dateRangeEndDate: filters.dateRangeEndDate,
  }
}

export const useRafflesCountQuery = () => {
  const { data: overviewSettings, isLoading: settingsIsLoading } = useRaffleOverviewSettingsQuery()
  const { limit, filters, sort } = overviewSettings || raffleOverviewDefaultSettings

  const { raffles } = useAdminApi()
  return useQuery({
    enabled: !settingsIsLoading,
    queryKey: ['raffles', 'count', filters, sort],
    queryFn: async () => {
      const page = 0
      const response = await raffles.getRafflesCount(getRaffleQueryParams(page, limit, filters, sort))

      return response
    },
  })
}

export const useNeedSettingsRafflesQuery = (page: number) => {
  const { data: overviewSettings, isLoading: settingsIsLoading } = useRaffleOverviewSettingsQuery()
  const { limit, filters, sort } = overviewSettings || raffleOverviewDefaultSettings

  const { raffles } = useAdminApi()
  return useQuery({
    enabled: !settingsIsLoading,
    queryKey: ['raffles', 'needSettings', page, limit, filters, sort],
    queryFn: async () => {
      const response = await raffles.getNeedSettingsRaffles(getRaffleQueryParams(page, limit, filters, sort))

      return response
    },
  })
}

export const useUpcomingRafflesQuery = (page: number) => {
  const { data: overviewSettings, isLoading: settingsIsLoading } = useRaffleOverviewSettingsQuery()
  const { limit, filters, sort } = overviewSettings || raffleOverviewDefaultSettings

  const { raffles } = useAdminApi()
  return useQuery({
    enabled: !settingsIsLoading,
    queryKey: ['raffles', 'upcoming', page, limit, filters, sort],
    queryFn: async () => {
      const response = await raffles.getUpcomingRaffles(getRaffleQueryParams(page, limit, filters, sort))
      return response as GetPaginatedRaffles
    },
  })
}

export const useLiveRafflesQuery = (page: number) => {
  const { data: overviewSettings, isLoading: settingsIsLoading } = useRaffleOverviewSettingsQuery()
  const { limit, filters, sort } = overviewSettings || raffleOverviewDefaultSettings

  const { raffles } = useAdminApi()
  return useQuery({
    enabled: !settingsIsLoading,
    queryKey: ['raffles', 'live', page, limit, filters, sort],
    queryFn: async () => {
      const response = await raffles.getLiveRaffles(getRaffleQueryParams(page, limit, filters, sort))
      return response as GetPaginatedRaffles
    },
  })
}

export const usePastRafflesQuery = (page: number) => {
  const { data: overviewSettings, isLoading: settingsIsLoading } = useRaffleOverviewSettingsQuery()
  const { limit, filters, sort } = overviewSettings || raffleOverviewDefaultSettings

  const { raffles } = useAdminApi()
  return useQuery({
    enabled: !settingsIsLoading,
    queryKey: ['raffles', 'past', page, limit, filters, sort],
    queryFn: async () => {
      const response = await raffles.getPastRaffles(getRaffleQueryParams(page, limit, filters, sort))
      return response as GetPaginatedRaffles
    },
  })
}

export const useAllRafflesQuery = (page: number) => {
  const { data: overviewSettings, isLoading: settingsIsLoading } = useRaffleOverviewSettingsQuery()
  const { limit, filters, sort } = overviewSettings || raffleOverviewDefaultSettings

  const { raffles } = useAdminApi()
  return useQuery({
    enabled: !settingsIsLoading,
    queryKey: ['raffles', 'all', page, limit, filters, sort],
    queryFn: async () => {
      const response = await raffles.getAllRaffles(getRaffleQueryParams(page, limit, filters, sort))
      return response as GetPaginatedRaffles
    },
  })
}

export const useRandomProductImageQuery = () => {
  const { raffles } = useAdminApi()
  return useQuery({
    queryKey: ['randomProductImage'],
    queryFn: async () => {
      const response = await raffles.getRandomProductImage()
      return response as GetRandomProductImageResponse
    },
  })
}

export const usePutRaffleCustomFieldMutation = () => {
  const { raffles } = useAdminApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      pathParams,
      data,
    }: {
      pathParams: UpdateRaffleCustomFieldPathParams
      data: UpdateRaffleCustomFieldRequestBody
    }) => {
      const response = await raffles.updateCustomField(
        {
          sku: pathParams.sku,
          instance: pathParams.instance,
        },
        data,
      )
      return response
    },
    onSuccess: (data, variables) => {
      const { instance, sku } = variables.pathParams
      queryClient.setQueryData(['raffle', instance, sku], data)
    },
  })
}

export const usePostUnpublishRaffleMutation = () => {
  const { raffles } = useAdminApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ sku, instance }: PublishRafflePathParams) => {
      const response = await raffles.unpublishRaffle({
        sku,
        instance,
      })
      return response
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['raffle', variables.instance, variables.sku] })
    },
  })
}

export const usePostPublishRaffleMutation = () => {
  const { raffles } = useAdminApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ sku, instance }: PublishRafflePathParams) => {
      const response = await raffles.publishRaffle({
        sku,
        instance,
      })
      return response
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['raffle', variables.instance, variables.sku] })
    },
  })
}

export const useGetScheduleRaffle = (sku: string, instance: BCInstance | null) => {
  const { raffles } = useAdminApi()
  return useQuery({
    refetchInterval: 30 * 1000,
    enabled: !!instance && !!sku,
    queryKey: ['schedule', instance, sku],
    queryFn: async () => {
      if (!instance || !sku) {
        return null
      }

      const response = await raffles.getScheduleRaffle({
        sku,
        instance,
      })

      return response
    },
  })
}

export const usePostScheduleRaffleMutation = () => {
  const { raffles } = useAdminApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      pathParams: { sku, instance },
      data,
    }: {
      pathParams: ScheduleRafflePathParams
      data: ScheduleRaffleRequestBody
    }) => {
      const response = await raffles.scheduleRaffle(
        {
          sku,
          instance,
        },
        data,
      )
      return response
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['schedule', variables.pathParams.instance, variables.pathParams.sku] })
    },
  })
}

export const usePostFinalizeRaffleMutation = () => {
  const { raffles } = useAdminApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ sku, instance }: FinalizeRafflePathParams) => {
      const response = await raffles.finalizeRaffle({
        sku,
        instance,
      })
      return response
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['raffle', variables.instance, variables.sku] })
    },
  })
}

export const usePostUnFinalizeRaffleMutation = () => {
  const { raffles } = useAdminApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ sku, instance }: FinalizeRafflePathParams) => {
      const response = await raffles.unFinalizeRaffle({
        sku,
        instance,
      })
      return response
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['raffle', variables.instance, variables.sku] })
    },
  })
}

export const usePostTransactionalToRaffleMutation = () => {
  const { raffles } = useAdminApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ sku, instance }: TransformRaffleToTransactionalPathParams) => {
      const response = await raffles.transformRaffleToTransactional({
        sku,
        instance,
      })
      return response
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['raffle', variables.instance, variables.sku] })
    },
  })
}

export const usePostRaffleToTransactionalMutation = () => {
  const { raffles } = useAdminApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ sku, instance }: TransformRaffleToTransactionalPathParams) => {
      const response = await raffles.transformTransactionalToRaffle({
        sku,
        instance,
      })
      return response
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['raffle', variables.instance, variables.sku] })
    },
  })
}
