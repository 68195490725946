import type { RaffleFilters, RaffleSort } from '@sneakersnstuff/sns-api-client/admin'
import type { BCInstance } from '@sneakersnstuff/sns-api-client/app/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { type NextRouter, useRouter } from 'next/router'
import type { DateRangeKeys } from '~/components/raffles/overview/RafflesOverviewDateRange'
import { getLocalStorageItem, setLocalStorageItem } from '~/services/localstorage'

export interface RaffleOverviewSettings {
  limit: number
  page: number
  filters: RaffleFilters
  sort: RaffleSort
}

const raffleOverviewSettings: RaffleOverviewSettings = {
  limit: JSON.parse(getLocalStorageItem('raffleLimit') || '8'),
  page: 0,
  filters: {
    delayed: 'all',
    instances: ['EU', 'UK', 'US'],
    keyword: '',
    dateRangeKey: 'none',
    dateRangeStartDate: new Date().toISOString(),
    dateRangeEndDate: new Date().toISOString(),
  },
  sort: {
    sortKey: 'releaseDate',
    sortOrder: 'desc',
  },
}

export const raffleOverviewDefaultSettings: RaffleOverviewSettings = { ...raffleOverviewSettings }

export const setStateInUrl = (router: NextRouter, queryState: Record<string, string | BCInstance[] | null>) => {
  const pathname = router.pathname
  const query = { ...router.query, ...queryState }

  router.push({ pathname, query }, undefined, {})
}

export const useRaffleOverviewSettingsQuery = () => {
  const router = useRouter()

  return useQuery({
    enabled: Object.keys(router.query).length > 0,
    queryKey: ['rafflesOverview'],
    queryFn: async () => {
      const settings = { ...raffleOverviewSettings }
      if (router.query.page) {
        settings.page = Number.parseInt(router.query.page.toString(), 10)
      }
      if (router.query.delayedFilter) {
        settings.filters.delayed = router.query.delayedFilter.toString() as RaffleFilters['delayed']
      }
      if (router.query.instancesFilter) {
        if (typeof router.query.instancesFilter === 'string') {
          settings.filters.instances = [router.query.instancesFilter] as BCInstance[]
        } else {
          settings.filters.instances = router.query.instancesFilter as BCInstance[]
        }
      }
      if (router.query.search !== undefined) {
        settings.filters.keyword = router.query.search.toString()
      } else {
        settings.filters.keyword = undefined
      }

      if (router.query.dateRangeKey) {
        settings.filters.dateRangeKey = router.query.dateRangeKey.toString() as RaffleFilters['dateRangeKey']
      }

      if (router.query.dateRangeStartDate) {
        settings.filters.dateRangeStartDate = router.query.dateRangeStartDate.toString()
      }

      if (router.query.dateRangeEndDate) {
        settings.filters.dateRangeEndDate = router.query.dateRangeEndDate.toString()
      }

      return settings
    },
  })
}

export const useRaffleOverviewSettingsPageMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (page: number) => {
      raffleOverviewSettings.page = page
      return raffleOverviewSettings
    },
    onSuccess: (data: RaffleOverviewSettings) => {
      queryClient.setQueryData(['rafflesOverview'], data)
    },
  })
}

export const useRaffleOverviewSettingsSortMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      sortKey,
      sortOrder,
    }: { sortKey: RaffleSort['sortKey']; sortOrder: RaffleSort['sortOrder'] }) => {
      raffleOverviewSettings.sort.sortKey = sortKey
      raffleOverviewSettings.sort.sortOrder = sortOrder
      return raffleOverviewSettings
    },
    onSuccess: (data: RaffleOverviewSettings) => {
      queryClient.setQueryData(['rafflesOverview'], data)
    },
  })
}

export const useRaffleOverviewSettingsDelayedMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (delayed: RaffleFilters['delayed']) => {
      raffleOverviewSettings.filters.delayed = delayed
      return raffleOverviewSettings
    },
    onSuccess: (data: RaffleOverviewSettings) => {
      queryClient.setQueryData(['rafflesOverview'], data)
    },
  })
}

export const useRaffleOverviewSettingsDateRangeKeyMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: {
      key: RaffleFilters['dateRangeKey']
      startDate: RaffleFilters['dateRangeStartDate']
      endDate: RaffleFilters['dateRangeEndDate']
    }) => {
      raffleOverviewSettings.filters.dateRangeKey = data.key
      raffleOverviewSettings.filters.dateRangeStartDate = data.startDate
      raffleOverviewSettings.filters.dateRangeEndDate = data.endDate
      return raffleOverviewSettings
    },
    onSuccess: (data: RaffleOverviewSettings) => {
      queryClient.setQueryData(['rafflesOverview'], data)
    },
  })
}

export const useRaffleOverviewSettingsInstanceMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (instances: BCInstance[]) => {
      raffleOverviewSettings.filters.instances = instances
      return raffleOverviewSettings
    },
    onSuccess: (data: RaffleOverviewSettings) => {
      queryClient.setQueryData(['rafflesOverview'], data)
    },
  })
}

export const useRaffleOverviewSettingsLimitMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (limit: number) => {
      setLocalStorageItem('raffleLimit', limit.toString())
      raffleOverviewSettings.limit = limit
      return raffleOverviewSettings
    },
    onSuccess: (data: RaffleOverviewSettings) => {
      queryClient.setQueryData(['rafflesOverview'], data)
    },
  })
}

export const useRaffleOverviewSettingsSearchMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (search: string) => {
      raffleOverviewSettings.filters.keyword = search
      return raffleOverviewSettings
    },
    onSuccess: (data: RaffleOverviewSettings) => {
      queryClient.setQueryData(['rafflesOverview'], data)
    },
  })
}
