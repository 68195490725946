'use client'

import { type AdminApiClient, createAdminClient } from '@sneakersnstuff/sns-api-client/admin'
import { BackendErrors } from '@sneakersnstuff/sns-api-client/app/types/errors'
import { signOut } from 'next-auth/react'

import { type ReactNode, useEffect, useState } from 'react'
import { useToast } from '~/components/shadcn/use-toast'
import { useEnvironment } from '~/hooks'

import { createContext } from '~/lib/createContext'

export const [Provider, useAdminApi] = createContext<AdminApiClient>({
  errorMessage: 'Using useAdminApi before ApiProvider.',
  name: 'SNS_ADMIN_API',
})

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const [api, setApi] = useState<AdminApiClient | null>(null)
  const { selectedEnvironment } = useEnvironment()
  const { toast } = useToast()

  useEffect(() => {
    let baseUrl = window.location.origin

    if (selectedEnvironment === 'dev') {
      baseUrl = `${baseUrl}/api/dev`
    }

    const buildApi = () => {
      const client = createAdminClient({
        baseUrl,
        // biome-ignore lint/suspicious/noExplicitAny: typing is wrong, need to fix it
        errorHandler: (errorMessage: any) => {
          if (errorMessage.message === BackendErrors.authInvalidToken) {
            console.log('logging you out')
            return signOut()
          }

          // User reloads the page
          if (errorMessage.error?.message === 'Request aborted') {
            return false
          }

          if (errorMessage.error) {
            toast({
              duration: 15000,
              variant: 'destructive',
              title: `${errorMessage.code}: ${errorMessage.error.name}`,
              description: (
                <div>
                  <p>{errorMessage.error.message}</p>
                  <pre>{errorMessage.error.stack}</pre>
                </div>
              ),
            })
            return false
          }

          if (errorMessage.code) {
            toast({
              duration: 15000,
              variant: 'destructive',
              title: errorMessage.code,
            })
            return false
          }

          console.log('errorMessage', errorMessage)
          toast({
            duration: 15000,
            variant: 'destructive',
            title: 'Unknown Error',
            description: (
              <div>
                <pre>{JSON.stringify(errorMessage, null, 4)}</pre>
              </div>
            ),
          })

          return false
        },
      })

      setApi(client)
    }

    buildApi()
  }, [toast, selectedEnvironment])

  if (!api) {
    return null
  }

  return <Provider value={api}> {children} </Provider>
}
